<template>
  <div class="total">
    <div class="configure">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane disabled label="作业" name="first"> </el-tab-pane>
        <el-tab-pane label="课后作业" name="first">
          <div  class="work-title bg-white flex justify-content-center" >
         
 <el-button round  :class="{'color-box':jobList}"    @click="job_List(true)">作业列表</el-button> <el-button  :class="{'color-box':!jobList}"    round @click="job_List()">草稿箱</el-button>
          </div>

          <div  v-if="jobList"  class="work-title bg-white">
            <div
              class="work-title-all flex justify-content-between aligin-items-center flex-wrap-wrap" >
              <div class="work-title-content flex aligin-items-center">
                <div class="cursor" @click="openDate">
                  <span>布置时间</span>
                  <i class="el-icon-caret-bottom" />
                  <span class="border-right" />
                </div>
                <el-popover width="150" trigger="click">
                  <el-input
                    v-model="classInput"
                    placeholder=""
                    size="mini"
                  ></el-input>
                  <el-checkbox-group
                    v-model="shool_list"
                    v-for="(item, index) in shoolList"
                    :key="index"
                  >
                    <el-checkbox class="padding-xs" :label="item.label" />
                  </el-checkbox-group>
                  <div slot="reference" class="cursor">
                    <span>校区</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
                <el-popover width="200" trigger="click">
                  <el-input
                    v-model="teachersInput"
                    placeholder=""
                    size="mini"
                  ></el-input>
                  <el-checkbox-group
                    v-model="ArrangeTeachers"
                    v-for="(item, index) in ArrangeTeachersList"
                    :key="index"
                  >
                    <el-checkbox class="padding-xs" :label="item.label" />
                  </el-checkbox-group>
                  <div slot="reference" class="cursor">
                    <span>布置教师</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
                <el-popover width="150" trigger="click">
                  <el-input
                    v-model="classInput"
                    placeholder=""
                    size="mini"
                  ></el-input>
                  <el-checkbox-group
                    v-model="ArrangeTeachers"
                    v-for="(item, index) in classList"
                    :key="index"
                  >
                    <el-checkbox class="padding-xs" :label="item.label" />
                  </el-checkbox-group>
                  <div slot="reference" class="cursor">
                    <span>班级</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
                <el-popover width="150" trigger="click">
                  <el-radio
                    v-for="(item, index) in whether"
                    :key="index"
                    v-model="evaluatelist"
                    :label="item.label"
                    >{{ item.label }}</el-radio
                  >
                  <div slot="reference" class="cursor">
                    <span>是否全部点评</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
              </div>

              <div class="work-title-search">
                <el-input
                  placeholder="请输入作业标题"
                  v-model="stuInput"
                  class="input-with-select"
                  size="mini"
                >
                  <el-button slot="append" icon="el-icon-search" />
                </el-input>
                <div
                  class="cursor text-blue margin-top-sm flex justify-content-flexEnd"
                  @click="clearFilter"
                >
                  清空筛选
                </div>
              </div>
            </div>

            <div
              class="work-title-status flex aligin-items-center justify-content-between flex-wrap-wrap" >
              <div class="flex aligin-items-center flex-wrap-wrap">
                <div
                  class="text-gray flex aligin-items-center flex-wrap-wrap margin-top-sm">
                  <div>布置时间：</div>
                  <!-- 日期组件 -->
                  <el-date-picker
                    ref="datePicker"
                    v-model="value1"
                    type="daterange"
                    
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    size="mini"
                  />
                </div>
                <div
                  v-if="shool_list.length"
                  class="text-gray flex aligin-items-center margin-left-lg flex-wrap-wrap margin-top-sm"
                >
                  <div>校区：</div>
                  <div v-for="(btn, btnIndex) in shool_list" :key="btnIndex">
                    <div :class="{ 'margin-left': btnIndex > 0 }">
                      <el-button size="mini">
                        {{ btn }}
                        <i
                          class="el-icon-close"
                          @click="delOderStatus(btnIndex)"
                        />
                      </el-button>
                    </div>
                  </div>
                </div>
                <div
                  v-if="ArrangeTeachers.length"
                  class="text-gray flex aligin-items-center flex-wrap-wrap margin-top-sm">
                  <div>布置教师：</div>
                  <div
                    v-for="(btn, btnIndex) in ArrangeTeachers"
                    :key="btnIndex"
                  >
                    <div :class="{ 'margin-left': btnIndex > 0 }">
                      <el-button size="mini">
                        {{ btn }}
                        <i
                          class="el-icon-close"
                          @click="teachersStatus(btnIndex)"
                        />
                      </el-button>
                    </div>
                  </div>
                </div>
                <div
                  v-if="classlist.length"
                  class="text-gray flex aligin-items-center margin-left-lg flex-wrap-wrap margin-top-sm">
                  <div>班级：</div>
                  <div v-for="(btn, btnIndex) in classlist" :key="btnIndex">
                    <div :class="{ 'margin-left': btnIndex > 0 }">
                      <el-button size="mini">
                        {{ btn }}
                        <i
                          class="el-icon-close"
                          @click="classStatus(btnIndex)"
                        />
                      </el-button>
                    </div>
                  </div>
                </div>
                <div
                  v-if="evaluatelist.length"
                  class="text-gray flex aligin-items-center margin-left-lg flex-wrap-wrap margin-top-sm"
                >
                  <div>是否全部点评：</div>
                  <div v-for="(btn, btnIndex) in evaluatelist" :key="btnIndex">
                    <div :class="{ 'margin-left': btnIndex > 0 }">
                      <el-button size="mini">
                        {{ btn }}
                        <i
                          class="el-icon-close"
                          @click="evaluateStatus(btnIndex)"
                        />
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div class="margin-top-sm">
            <el-button
              type="primary"  
              @click="JumpUrl('/parentCenter/Assignment')"
              >布置作业</el-button
            >
          </div>
          <div style="margin-top: 10px">共计1个作业</div>
          <div style="margin-top: 10px">
            <as-list :initdataList="datalist">
              <el-table-column
                align="center"
                prop="banji"
                label="作业标题"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="name"
                label="班级/一对一"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="name1"
                label="布置教师"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text3"
                label="状态"
              ></el-table-column>
              <el-table-column
                align="center"
                width="250"
                prop="text5"
                label="点评进度（已点评/已提交）"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text4"
                label="未提交"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text5"
                label="未读"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="text7"
                label="布置时间"
              ></el-table-column>
              <el-table-column align="center" label="操作">
                <span   class="cursor text-blue">删除</span>
              </el-table-column>
            </as-list>
          </div>
          </div>
          <div v-if="!jobList" class="work-title bg-white">
            <div style="margin-top: 10px">共计0个作业</div>
          <div style="margin-top: 10px">
            <as-list :initdataList="datalist1">
              <el-table-column
                align="center"
                prop="banji"
                label="作业标题"
              ></el-table-column>
             
              <el-table-column
                align="center"
                prop="text7"
                label="最后编辑时间"
              ></el-table-column>
              <el-table-column align="center" label="操作">
                <span    class="cursor text-blue">删除</span>
              </el-table-column>
            </as-list>
          </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <ClassRecord
      :Visible="editVisible && editType === 4"
      @closepop="closeEditPop"
    ></ClassRecord>
  </div>
</template>
<script>
import ClassRecord from "../components/ClassRecord.vue";
import AsList from "@/components/as-list";
export default {
  components: {
    AsList,
    ClassRecord,
  },
  data() {
    return {
      jobList:true,
      stuInput: "",
      teachersInput: "",
      editType: 1,
      evaluatelist: [], //是否点评
      shool_list: [], //校区
      classlist: [], //班级
      ArrangeTeachers: [], //布置教师
      datalist1:[],
      datalist: [
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
        {
          banji: "寒假愉快",
          name: "万豪_艺术区高二",
          name1: "周杰",
          text3: "进行中",
          text4: "已提交",
          text5: "已提交",
          text6: "是",
          text7: "2021-03-07",
        },
      ],

      options1: [
        {
          value: "是",
          label: "是",
        },
        {
          value: "否",
          label: "否",
        },
      ],

      options: [
        {
          value: "校区一",
          label: "校区一",
        },
        {
          value: "校区二",
          label: "校区二",
        },
        {
          value: "校区三",
          label: "校区三",
        },
      ], //校区
      value: "",
      activeName: "first",
      value1: "",
      value2: "",
    };
  },
  methods: {
    job_List(t){
      if(t){ 
      this.jobList=true
      }else{ 
      this.jobList=false
      }
    },
    clearFilter() {
      this.shool_list = [];
      this.ArrangeTeachers = [];
      this.classlist = [];
      this.evaluatelist = [];
    },
    openDate() {
      this.$refs.datePicker.pickerVisible = true;
    },
    //布置教师
    teachersStatus(index) {
      this.ArrangeTeachers.splice(index, 1);
    },
    //班级
    classStatus(index) {
      this.classlist.splice(index, 1);
    },
    //是否点评
    evaluateStatus(index) {
      this.evaluatelist = [];
    },

    delOderStatus(index) {
      this.shool_list.splice(index, 1);
    },
    JumpUrl(url) {
      this.$router.push(url);
    },
    handleClick(row) {
      console.log(row);
    },
    editHandle(type) {
      this.editVisible = true;
      this.editType = type;
    },
  },
};
</script>

<style lang="scss">
.color-box {
		color: #0081ff;
		border: 1px solid #8cc5ff;
		background-color: #e8f5ff;
	}
  
.total {
  width: 100%;
}
.TopLabel {
  widows: 200px;
}
.TopLabel_1 {
  widows: 300px;
}

.configure {
  width: 100%;
  background-color: #fff;

  padding: 30px 10px 10px 10px;
}
</style>